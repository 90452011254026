import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { eClaimActions } from "../../../actions";
import { bindActionCreators } from "redux";

import Enums from "../../../utils/Enums";
import SelectInput from "./common/SelectInput";
import TablePaginationActions from "../../../components/TablePaginationActions";

const FORMAT_DATE = "DD/MM/YYYY";

const CLAIM_STATUS_OPTIONS = [
  {
    label: "Select All",
    value: "ALL"
  },
  {
    label: "Action Needed",
    value: "ACTION_NEEDED"
  },
  {
    label: "Approved",
    value: "APPROVED"
  },
  {
    label: "On Hold",
    value: "ON_HOLD"
  },
  {
    label: "Processing",
    value: "PROCESSING"
  },
  {
    label: "Rejected",
    value: "REJECTED"
  },
  {
    label: "Submitted",
    value: "SUBMITTED"
  }
];

class ClaimList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allClaims: props.filteredClaimList || [], // Original data from parent
      filteredClaims: [], // Filtered claims after applying filters
      displayedClaims: [], // Current page's data
      currentPage: 0,
      rowsPerPage: 10,
      claimStatus: "ALL"
    };
  }

  componentDidMount() {
    this.initializeData(this.props.filteredClaimList);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filteredClaimList !== this.props.filteredClaimList) {
      this.initializeData(this.props.filteredClaimList);
    }
  }

  initializeData = allClaims => {
    if (allClaims) {
      const filteredClaims = this.filterClaims(
        allClaims,
        this.state.claimStatus
      );
      this.updateDisplayedClaims(filteredClaims, 0);
      this.setState({ allClaims, filteredClaims });
    }
  };

  filterClaims = (claims, status) => {
    if (status === "ACTION_NEEDED") {
      return claims.filter(claim =>
        ["EDIT_REQUESTED", "PENDING"].includes(claim.claim_status)
      );
    }

    return claims.filter(claim =>
      status === "ALL" ? true : claim.claim_status === status
    );
  };

  updateDisplayedClaims = (filteredClaims, pageIndex) => {
    const { rowsPerPage } = this.state;
    const startIndex = pageIndex * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    this.setState({
      displayedClaims: filteredClaims.slice(startIndex, endIndex),
      currentPage: pageIndex
    });
  };

  handle = e => {
    const selectedClaimStatus = e.target.value;
    const filteredClaims = this.filterClaims(
      this.state.allClaims,
      selectedClaimStatus
    );

    this.setState(
      {
        claimStatus: selectedClaimStatus,
        filteredClaims
      },
      () => this.updateDisplayedClaims(filteredClaims, 0) // Reset to page 0
    );
  };

  onChangePage = (event, pageIndex) => {
    this.updateDisplayedClaims(this.state.filteredClaims, pageIndex);
  };

  render() {
    const {
      classes,
      handleMakeClaim,
      handleViewClaim,
      getStatusText,
      user
    } = this.props;
    const {
      displayedClaims,
      currentPage,
      rowsPerPage,
      filteredClaims,
      claimStatus
    } = this.state;

    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <div style={{ display: "flex" }}>
            <Grid container wrap="wrap" spacing={"8px"}>
              <Grid item xs={3}>
                <Typography
                  className={classes.heading}
                  variant="h5"
                  color="inherit"
                >
                  My Claims
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  className={classes.label}
                  align="right"
                  style={{ paddingTop: 6, paddingBottom: 7 }}
                >
                  <strong>Claim Status</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <SelectInput
                  name={"claimStatus"}
                  value={claimStatus}
                  onChange={this.handle}
                  list={CLAIM_STATUS_OPTIONS}
                  className={classes.dropdown}
                />
              </Grid>
              <Grid item xs={2} align="right">
                {user.auth.scope.includes(Enums.SCOPE_TYPE.CLAIM_W) && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleMakeClaim}
                  >
                    Make A Claim
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 10 }}>
            {displayedClaims.length === 0 && (
              <Typography
                className={classes.heading}
                variant="subheading"
                color="inherit"
              >
                {filteredClaims.length === 0
                  ? "No claim submitted yet."
                  : "No Record Found"}
              </Typography>
            )}
            <Grid container spacing={"8px"}>
              {displayedClaims.map((data, idx) => (
                <Grid
                  item
                  xs={12}
                  style={{ padding: 0 }}
                  onClick={handleViewClaim(data.id)}
                  key={idx}
                >
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container spacing={"8px"}>
                        <Grid item xs={8}>
                          <Typography>{data.claimant}</Typography>
                          <Typography>
                            <strong>Reference No: </strong>
                            {data.reference_number || "-"}
                          </Typography>
                          <Typography>
                            <strong>Claim No: </strong>
                            {data.claim_status === "SUBMITTED"
                              ? "-"
                              : data.claim_no || "-"}
                          </Typography>
                          <Typography>
                            <strong>Bill Amount: </strong>
                            {data.currency} {data.bill_amount}
                          </Typography>
                          <Typography>
                            <strong>Submitted Date: </strong>
                            {data.received_date}
                          </Typography>
                          <Typography>
                            <strong>Date of Visit: </strong>
                            {data.date_of_consultation}
                          </Typography>
                          {data.hr_claim && (
                            <Typography style={{ color: "coral" }}>
                              Submitted by HR
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {getStatusText(data.claim_status)}
                          <Typography>
                            {data.pay_back_amount
                              ? `$${data.pay_back_amount}`
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {displayedClaims.length > 0 && (
              <TablePagination
                className={classes.paginator}
                colSpan={5}
                count={filteredClaims.length} // Use filteredClaims length
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={this.onChangePage}
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ count, page }) =>
                  `Page ${page + 1} - Total ${Math.ceil(count / rowsPerPage)}`
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  card: {
    marginTop: 10
  },
  paginator: {
    float: "right"
  },
  button: {
    minWidth: 150
  },
  spacing: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: 14
    }
  }
});

function mapStateToProps(state) {
  const eclaim = state.eclaim;
  const user = state.user;
  return {
    list: eclaim.claims.items,
    filteredClaimList: eclaim.claims,
    user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(eClaimActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClaimList));
