import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";
import { eClaimActions } from "../../../../actions";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

import ConfirmDialog from "../ConfirmDialog";
import LoadingPopup from "../../../../components/LoadingPopup";
import Util from "../../../../utils/Util";
import Enums from "../../../../utils/Enums";
import ClaimEditClaimTypeSection from "./ClaimEditClaimTypeSection";
import ClaimEditDiagnosisSection from "./ClaimEditDiagnosisSection";
import ClaimEditPaymentSection from "./ClaimEditPaymentSection";
import ClaimEditSupportingDocumentsSection from "./ClaimEditSupportingDocumentsSection";
import ClaimEditSubmittedSection from "./ClaimEditSubmittedSection";
import { ValidationName, ValidationRule } from "./claimValidationRule";
import { nricValidation } from "../../../../utils/nricValidation";

const acceptedImageExtensions = new Set([".png", ".jpg", ".jpeg"]);
export const isImage = name => {
  return acceptedImageExtensions.has(
    `.${name
      .toLowerCase()
      .split(".")
      .pop()}`
  );
};

const FORMAT_DATE = "DD/MM/YYYY";

const ClaimEdit = ({
  classes,
  selectedClaim,
  loggedUser,
  addClaim,
  editClaim,
  eclaim,
  clearUploadFileError,
  handleCloseClaim,
  open,
  getStatusText
}) => {
  const [claim, setClaim] = useState(() => {
    let claim = {
      accident_date: null,
      accident_description: "",
      accident_work_related: "N",
      bank_account_holder_name: "",
      bank_account_number: "",
      bank_branch_id: "",
      bank_id: "",
      bank_name: "",
      bill_amount: "",
      benefit_type: null,
      cause_of_claim_type: "-",
      claim_type: "-",
      claimant_id: `${loggedUser.id}`,
      claimant_remarks: "",
      clinic_id: "",
      clinic_name: "",
      currency: "SGD",
      date_of_consultation: null,
      diagnosis_id: "",
      diagnosis_name: "",
      edit_images: [],
      error: "",
      illness_description: "",
      illness_start_date: "",
      invoice_no: "",
      images: [],
      name: "Claim",
      notes: "",
      payment_method: "-",
      gst_indicator: null,
      amount_exclude_gst: null,
      medisave_indicator: null,
      other_insurer_indicator: null
    };

    if (selectedClaim) {
      // images wil be processed in useEffect
      const { images, ...tempSelectedClaim } = selectedClaim;
      claim = { ...claim, ...tempSelectedClaim };
      claim["claimant_id"] = `${selectedClaim.claimant_id}`;
    }
    return claim;
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { claim_status: claimStatus, edit_images: editImages, notes } = claim;
  const { coverage_start_date, auth: { pcode } = {} } = loggedUser;
  // componentWillMount
  useEffect(() => {
    if (
      selectedClaim &&
      (claimStatus === Enums.CLAIMS_STATUS.SUBMITTED ||
        claimStatus === Enums.CLAIMS_STATUS.EDIT_REQUESTED)
    ) {
      convertImageFromString(selectedClaim.images || []);
    }
    ValidatorForm.addValidationRule("isSelectRequired", value => {
      if ("-" === value || Util.isEmpty(value)) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("notNegative", value => {
      if (Util.isEmpty(value)) {
        return true;
      }

      if (isNaN(value)) {
        return false;
      }

      return parseFloat(value) > 0;
    });
    let coverageStartDate = null;
    if (!Util.isEmpty(coverage_start_date)) {
      coverageStartDate = moment(coverage_start_date, "DD/MM/YYYY");
    }

    ValidatorForm.addValidationRule("notPriorCoverageStartDate", value => {
      if (!value || !coverageStartDate) {
        return true;
      }
      return value >= coverageStartDate;
    });

    ValidatorForm.addValidationRule("specialCharacterRemark", value => {
      if (Util.specialCharacterCheck(value)) {
        return true;
      }
    });

    ValidatorForm.addValidationRule("isMaxCharValid", value => {
      if (Util.isMaxCharValid(value)) {
        return true;
      }
    });

    ValidatorForm.addValidationRule("invoiceNumberCheck", value => {
      if (Util.isInvoiceNumberValid(value)) {
        return true;
      }
    });

    ValidatorForm.addValidationRule(
      ValidationName.MAX_SUBMISSION_DAY,
      ValidationRule[ValidationName.MAX_SUBMISSION_DAY]
    );

    validateDefaultUserType();
  }, []);

  const validateSupportingDocuments = () => {
    if (isPCEClient && (!claim.images || !claim.images.length)) {
      setClaim(claim => ({
        ...claim,
        error: "At least one supporting document is required"
      }));
      return false;
    }
    if (
      claim.images &&
      claim.images.length &&
      !claim.images.every(
        image => image.name.length >= 2 && image.name.length < 100
      )
    ) {
      setClaim(claim => ({
        ...claim,
        error: "File name must between 2 and 99 characters"
      }));
      return false;
    }
    return true;
  };

  const formRef = React.createRef();
  const handleSubmitClick = event => {
    formRef.current.submit();
  };

  const handleSubmit = event => {
    if (!validateSupportingDocuments()) {
      return;
    }
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = event => {
    setShowConfirmDialog(false);
  };

  const handleConfirmedSubmit = event => {
    setShowConfirmDialog(false);
    setSubmitting(true);
    if (claimStatus === Enums.CLAIMS_STATUS.EDIT_REQUESTED) {
      const data = {
        notes,
        images: editImages
      };
      editClaim(id, data);
    } else {
      const { ...data } = claim;
      if (
        ![Enums.CLAIM_TYPE.INPATIENT, Enums.CLAIM_TYPE.MATERNITY].includes(
          data["claim_type"]
        )
      ) {
        data["cause_of_claim_type"] = null; //avoid pass -
      }
      addClaim(data);
    }
  };

  const validateDefaultUserType = () => {
    if (!claim.hasOwnProperty("reference_number")) {
      if (
        loggedUser.passport_nric.length === 9 &&
        nricValidation(loggedUser.passport_nric)
      ) {
        setClaim(claim => ({
          ...claim,
          payment_method: Enums.PAYMENT_METHOD.PAYNOW_TO_EMPLOYEE
        }));
      } else {
        setClaim(claim => ({
          ...claim,
          payment_method: Enums.PAYMENT_METHOD.GIRO_TO_EMPLOYEE
        }));
      }
    }
  };

  const convertImageFromString = images => {
    images.forEach(img => {
      if (isImage(img.name)) {
        const type = img.name.includes(".png") ? "png" : "jpeg";
        img = {
          ...img,
          data: `data:image/${type};base64,${img.data}`
        };
        var image = new Image();
        image.src = img.data;
        //Validate the File Height and Width.
        image.onload = function() {
          var height = image.height;
          var width = image.width;
          const newImageObj = {
            name: img.name,
            size: {
              height,
              width
            },
            data: img.data
          };
          setClaim(claim => ({
            ...claim,
            images: [...claim.images, newImageObj]
          }));
          return true;
        };
      } else {
        const newImageObj = {
          name: img.name,
          data: img.data
        };
        setClaim(claim => ({
          ...claim,
          images: [...claim.images, newImageObj]
        }));
      }
    });
  };

  const prevEclaim = useRef(eclaim);
  useEffect(() => {
    if (
      eclaim.uploadFileErrors &&
      submitting &&
      prevEclaim.current.uploadFileErrors !== eclaim.uploadFileErrors
    ) {
      setSubmitting(false);
    }
  }, [eclaim, prevEclaim]);

  const isPCEClient =
    pcode === Enums.P_CODE.PRU_CE || pcode === Enums.P_CODE.PRU_CE_EMULATE;
  const isPCEHr = pcode === Enums.P_CODE.PRU_CE_EMULATE;
  const isPTFHr = pcode === Enums.P_CODE.PRU_AGENCY_EMULATE;
  const readOnly = !!claimStatus;
  const shouldHide = readOnly && claimStatus !== Enums.CLAIMS_STATUS.SUBMITTED;

  const handleChange = name => event => {
    if (name == "invoice_no" && event.target.value.length > 20) {
      return false;
    }
    let value = "";
    if (!event) {
      // guard
    } else if (typeof event === "string") {
      value = event;
    } else if (typeof event === "object" && "target" in event) {
      value = event.target.value;
    } else if (typeof event === "object" && "value" in event) {
      value = event.value;
    }
    setClaim(claim => ({ ...claim, [name]: value }));

    switch (name) {
      case "claim_type":
        switch (value) {
          case Enums.CLAIM_TYPE.OUTPATIENT_DENTAL: {
            setClaim(claim => ({
              ...claim,
              cause_of_claim_type: "-",
              diagnosis_id: "K08",
              diagnosis_name: "",
              illness_description: "",
              illness_start_date: "",
              accident_description: "",
              accident_date: null,
              accident_work_related: "N",
              benefit_type: null
            }));
            break;
          }
          case Enums.CLAIM_TYPE.OUTPATIENT_HS_VAC: {
            setClaim(claim => ({
              ...claim,
              cause_of_claim_type: "-",
              diagnosis_id: "Z00",
              diagnosis_name: "",
              illness_description: "",
              illness_start_date: "",
              accident_description: "",
              accident_date: null,
              accident_work_related: "N",
              benefit_type: null
            }));
            break;
          }
          case Enums.CLAIM_TYPE.OUTPATIENT_GP: {
            setClaim(claim => ({
              ...claim,
              cause_of_claim_type: "-",
              illness_description: "",
              illness_start_date: "",
              accident_description: "",
              accident_date: null,
              accident_work_related: "N",
              benefit_type: null
            }));
            break;
          }
          default: {
            setClaim(claim => ({
              ...claim,
              cause_of_claim_type: "-",
              illness_description: "",
              illness_start_date: "",
              accident_description: "",
              accident_date: null,
              accident_work_related: "N",
              benefit_type: null
            }));
            break;
          }
        }
        break;
      case "claimant_id":
        if (isPCEClient) {
          handleChange("claim_type")("");
        }
        break;
      case "cause_of_claim_type":
        switch (value) {
          case Enums.CAUSE_OF_CLAIM.ILLNESS:
            setClaim(claim => ({
              ...claim,
              accident_description: "",
              accident_date: null,
              accident_work_related: "N"
            }));
            break;
          case Enums.CAUSE_OF_CLAIM.ACCIDENT:
            setClaim(claim => ({
              ...claim,
              illness_description: "",
              illness_start_date: ""
            }));
            break;
          default:
            setClaim(claim => ({
              ...claim,
              illness_description: "",
              illness_start_date: "",
              accident_description: "",
              accident_date: null,
              accident_work_related: "N"
            }));
            break;
        }
        break;
      case "payment_method":
        switch (value) {
          case Enums.PAYMENT_METHOD.CHEQUE: {
            setClaim(claim => ({
              ...claim,
              bank_id: "",
              bank_name: "",
              bank_account_holder_name: "",
              bank_branch_id: "",
              bank_account_number: ""
            }));
            break;
          }
          case Enums.PAYMENT_METHOD.GIRO_TO_EMPLOYEE: {
            setClaim(claim => ({
              ...claim,
              bank_id: "",
              bank_name: "",
              bank_account_holder_name: "",
              bank_branch_id: "",
              bank_account_number: ""
            }));
            break;
          }
          case Enums.PAYMENT_METHOD.GIRO_TO_COMPANY: {
            setClaim(claim => ({
              ...claim,
              bank_id: "",
              bank_name: "",
              bank_account_holder_name: "",
              bank_branch_id: "",
              bank_account_number: ""
            }));
            break;
          }
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleDateChange = name => date => {
    setClaim(claim => ({
      ...claim,
      [name]: (date && moment(date).format(FORMAT_DATE)) || null
    }));
  };

  const formatDate = date => {
    return (date && moment(date, FORMAT_DATE)) || null;
  };
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      fullScreen
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: classes.paper
      }}
    >
      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          <Typography variant="title" color="inherit" className={classes.flex}>
            {!!selectedClaim ? "View Claim" : "Make A Claim"}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleCloseClaim}
            aria-label="Close"
          >
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className={classes.paperRoot}>
          <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
            onError={errors => {}}
          >
            <Grid container spacing={"24px"}>
              <Grid item xs={12} md={6}>
                <ClaimEditSubmittedSection
                  claim={claim}
                  selectedClaim={selectedClaim}
                  getStatusText={getStatusText}
                />
                <ClaimEditClaimTypeSection
                  claim={claim}
                  eclaim={eclaim}
                  isPCEClient={isPCEClient}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  formatDate={formatDate}
                  readOnly={readOnly}
                  shouldHide={shouldHide}
                  loggedUser={loggedUser}
                />
                <ClaimEditDiagnosisSection
                  claim={claim}
                  isPCEClient={isPCEClient}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  formatDate={formatDate}
                  readOnly={readOnly}
                />
                <ClaimEditPaymentSection
                  claim={claim}
                  nricDetails={loggedUser.passport_nric}
                  setClaim={setClaim}
                  isPCEClient={isPCEClient}
                  handleChange={handleChange}
                  readOnly={readOnly}
                  shouldHide={shouldHide}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ClaimEditSupportingDocumentsSection
                  claim={claim}
                  eclaim={eclaim}
                  setClaim={setClaim}
                  readOnly={readOnly}
                  clearUploadFileError={clearUploadFileError}
                />
                <Button
                  disabled={readOnly}
                  className={classes.button}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitClick}
                >
                  Submit
                </Button>
              </Grid>
              <ConfirmDialog
                open={showConfirmDialog}
                closeHandler={closeConfirmDialog}
                okHandler={handleConfirmedSubmit}
                isPCEHr={isPCEHr}
                isPTFHr={isPTFHr}
              />
              <LoadingPopup name="Submit Claim" open={submitting} />
            </Grid>
          </ValidatorForm>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const styles = theme => ({
  appBar: {
    position: "fixed"
  },
  flex: {
    flex: 1
  },
  paperRoot: {
    marginTop: 60,
    padding: 10
  },
  button: {
    marginTop: 10
  }
});

function mapStateToProps(state, prop) {
  return { eclaim: state.eclaim, loggedUser: state.user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(eClaimActions, dispatch);
}
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClaimEdit))
);
