import { APIResource, APIResponse, APIError } from "../utils/APIResource";

export default class EClaimServices {
  static fetchClaimant() {
    return APIResource.get("/v1/claim/claimant").then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static addClaim(data) {
    return APIResource.post("/v1/claim", data).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static editClaim(id, data) {
    return APIResource.put(`/v1/claim/${id}`, data).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static fetchClaims() {
    let url = `/v1/claims`;
    return APIResource.get(url).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }

  static getClaim(claim_id) {
    return APIResource.get(`/v1/claim/${claim_id}`).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );
  }
}
