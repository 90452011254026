import * as types from "./types";
import claimServices from "../services/EClaimServices";
import { getEclaimState } from "../selectors/CommonSelectors";

export const eClaimActions = {
  fetchClaimant,
  addClaim,
  editClaim,
  getClaim,
  fetchClaims,
  viewClaim,
  closeClaim,
  showClaimErrorDialog,
  closeClaimErrorDialog,
  clearUploadFileError,
  clearClaimListError
};

function fetchClaimant() {
  return (dispatch, getState) => {
    claimServices.fetchClaimant().then(
      response => {
        if (response.success) {
          dispatch({
            type: types.GET_CLAIMANT_SUCCESS,
            claimants: response.data
          });
          return Promise.resolve(response);
        }
      },
      error => {
        dispatch({
          type: types.GET_CLAIMANT_ERROR,
          claimants: []
        });
        return Promise.reject(error);
      }
    );
  };
}

function addClaim(data) {
  return (dispatch, getState) => {
    let userLogged = getState().user;
    data["employee_id"] = userLogged.id;

    claimServices.addClaim(data).then(
      response => {
        if (response.success) {
          dispatch({
            type: types.GET_CLAIM_ADD_SUCCESS,
            claimants: response.data
          });
          return Promise.resolve(response);
        }
      },
      error => {
        if (error.code === 400 && error.message !== "") {
          let errors;
          if (
            typeof error.message === "string" ||
            error.message instanceof String
          ) {
            errors = [error.message];
          } else {
            errors = JSON.parse(error.message);
          }
          dispatch({
            type: types.GET_CLAIM_UPLOAD_FILE_ERROR,
            claimants: [],
            errors: errors
          });
        } else if (error.code === 413) {
          let errors;
          error.message = '["File size must not exceed 10MB"]';
          errors = [error.message];
          dispatch({
            type: types.GET_CLAIM_UPLOAD_FILE_ERROR,
            claimants: [],
            errors: errors
          });
        } else {
          dispatch({
            type: types.GET_CLAIM_ADD_ERROR,
            claimants: [],
            errors: "Error while submitting the claims."
          });
        }
        return Promise.reject(error);
      }
    );
  };
}

function editClaim(id, data) {
  return (dispatch, getState) => {
    claimServices.editClaim(id, data).then(
      response => {
        if (response.success) {
          dispatch({
            type: types.GET_CLAIM_ADD_SUCCESS,
            claimants: response.data
          });
          return Promise.resolve(response);
        }
      },
      error => {
        dispatch({
          type: types.GET_CLAIM_ADD_ERROR,
          claimants: []
        });
        return Promise.reject(error);
      }
    );
  };
}

function fetchClaims() {
  return (dispatch, getState) => {
    const currentState = getState();
    const { isLoading } = getEclaimState(currentState);

    if (isLoading) return;

    dispatch({
      type: types.LOADING_CLAIM
    });
    claimServices.fetchClaims().then(
      response => {
        if (response.success) {
          dispatch({
            type: types.GET_CLAIM_SUCCESS,
            claims: response.data
          });
          return Promise.resolve(response);
        }
      },
      error => {
        dispatch({
          type: types.GET_CLAIM_ERROR,
          claims: [],
          error: error.message
        });
        return Promise.reject(error);
      }
    );
  };
}

function viewClaim() {
  return dispatch => {
    dispatch({
      type: types.VIEW_CLAIM_DIALOG
    });
  };
}

function closeClaim() {
  return dispatch => {
    dispatch({
      type: types.CLOSE_CLAIM_DIALOG
    });
  };
}

function getClaim(idx) {
  return dispatch => {
    return claimServices.getClaim(idx).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function showClaimErrorDialog() {
  return dispatch => {
    dispatch({
      type: types.SHOW_CLAIM_DIALOG_ERROR
    });
  };
}

function clearUploadFileError(index) {
  return dispatch => {
    dispatch({
      type: types.CLEAR_UPLOAD_FILE_ERROR,
      index: index
    });
  };
}

function closeClaimErrorDialog() {
  return dispatch => {
    dispatch({
      type: types.CLOSE_CLAIM_DIALOG_ERROR
    });
  };
}

function clearClaimListError() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_CLAIM_LIST_ERROR
    });
  };
}
